<template>
	<div class="random_wrap">
		<div class="container">
			<div class="row">
				<div class="main_card_wrap">
					<div class="card_set right">
						<a href="">NFT 상세정보 <i class="icon icon-arrow-right"></i></a>
					</div>
					<span class="clear"></span>
					<div
						v-if="item.nft_token_id || item.nft_token_id == '0'"
						class="main_card"
					>
						<figure class="nft-card-info">
							<div class="o_square">
								<div class="inner">
									<div class="frame">
										<img
											:src="$request.upload_url(item.nft_card_img_url)"
											:alt="item.nft_card_name"
										/>
									</div>
								</div>
							</div>
							<figcaption>
								<strong>{{ item.nft_card_name }}</strong>
								<p>{{ item.nft_card_desctn }}</p>
							</figcaption>
						</figure>
						<div
							v-if="card_type == 'nft'"
							class="card_badge"
						>
							<span class="badge_40" :class="'badge_40_' + item.type"><em class="hide">music</em></span>
							<span class="badge_40 badge_40_capo hide"><em class="hide">capo</em></span>
						</div>
					</div>
				</div>
				<div class="drops_area">
					<p
						v-if="card_type == 'nft'"
						class="get_card">
						<b>MAFIA CARD</b>에서<br>
						<b>"{{ item.type }} 계열 카드"</b>를 획득했습니다!
					</p>
					<p
						v-else
						class="get_card"
					>
						<b>UTILITIES CARD</b>를 획득했습니다!
					</p>
					<div class="btn_area">
						<a
							v-if="card_type == 'nft'"
							@click="updateMainNft" class="btn_l btn_fill_blue"
						>메인카드 설정</a>
						<a @click="$emit('to', { name: 'drops'})" class="btn_l btn_fill_blue">확인</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia043'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'NFT 구매 완료'
				, title: 'UNION CARD'
				, not_footer: true
				, type: 'popup'
				, not_close: true
			}
			, my_card_id: this.$route.params.nft_id
			, item: {
				img_src: ''
			}
			, card_type: this.$route.params.card_type
		}
	}
	, methods: {
		getData: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_nft_detail
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.my_card_id
					}
					, type: true
				})
				if (result.success) {
					this.item = result.data
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		,updateMainNft: async function() {
			try {
				this.item.is_main = true
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.update_main_nft
					, data: this.item
					, name: 'updateMainNft'
				})

				if (result.success) {
					this.$bus.$emit('notify', {type: 'success', message: '정상적으로 처리되었습니다'})
					this.$emit('to', { name: 'nft'})
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message, config: {bottom: 0}})
				}
			} catch (e) {
				this.$bus.$emit('notify', {
					type: 'error',
					message: this.$language.common.error,
					config: {bottom: 0}
				})
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>